import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'
import assets4 from '../../../../../public/images/new-landing/assets-4.png'

export const swapFormData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Swap Any Token & Any Chain'),
  bodyText: t(
    'Welcome to PetaSwap, a trailblazer in the realm of DeFi addressing fundamental challenges in liquidity exchange. With a focus on security, transparency, and efficiency, PetaSwap establishes a decentralized ecosystem that empowers users to access liquidity in ways previously unprecedented.',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade now'),
    external: false,
  },
  secondaryButton: {
    to: '#',
    text: t('Learn more'),
    external: true,
  },
  images: {
    attributes: [
      { src: assets4, alt: '' },
      { src: assets4, alt: '' },
    ],
  },
})
