import styled from 'styled-components'
import {
  Flex,
  Heading,
  Text,
  Link,
  useMatchBreakpoints,
  Button,
  TwitterIcon,
  TelegramIcon,
  FlexGap,
} from '@pancakeswap/uikit'
import Image from 'next/image'
import { useTranslation } from '@pancakeswap/localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@pancakeswap/wagmi'
import SunburstSvg from './SunburstSvg'
import CompositeImage from './CompositeImage'
import PetaWithText from '../../../../public/images/peta-with-text.png'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`

const BottomRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
`

const topLeftImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '1-bottom', alt: 'Pancake flying on the bottom' },
    { src: '1-left', alt: 'Pancake flying on the left' },
    { src: '1-top', alt: 'Pancake flying on the top' },
  ],
}

const bottomRightImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '2-bottom', alt: 'Pancake flying on the bottom' },
    { src: '2-top', alt: 'Pancake flying on the top' },
    { src: '2-right', alt: 'Pancake flying on the right' },
  ],
}

const Footer = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <>
      {/* <BgWrapper>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <StyledSunburst />
        </Flex>
      </BgWrapper> */}
      {/* {(isTablet || isDesktop) && ( */}
      {/*   <FloatingPancakesWrapper> */}
      {/*     <TopLeftImgWrapper> */}
      {/*       <CompositeImage {...topLeftImage} maxHeight="256px" /> */}
      {/*     </TopLeftImgWrapper> */}
      {/*     <BottomRightImgWrapper> */}
      {/*       <CompositeImage {...bottomRightImage} maxHeight="256px" /> */}
      {/*     </BottomRightImgWrapper> */}
      {/*   </FloatingPancakesWrapper> */}
      {/* )} */}
      <Flex alignItems="center" flexDirection="column">
        <Image src={PetaWithText} height={40} width={138} alt="" unoptimized />
        <FlexGap height={32} />

        <Heading
          mb="24px"
          textAlign="center"
          fontWeight={600}
          fontSize={['14px !important', null, null, '18px']}
          color="secondary"
        >
          {t('Join us at PetaSwap and be part of the true DeFi revolution!')}
        </Heading>
        <FlexGap height={32} />

        <Flex
          width="100%"
          gap={'24px'}
          justifyContent={['center', null, null, 'center']}
          alignItems={['start', null, null, 'center']}
          flexDirection={['row', null, null, 'row']}
          flexWrap={'wrap'}
        >
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            Campaigns
          </Text>
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            Email Marketing
          </Text>
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            Branding
          </Text>
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            Offline
          </Text>
          <Link href="/privacy">
            <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
              Privacy Policy
            </Text>
          </Link>
          <Link href="https://whitepaper.petaswap.io/" rel="noopener noreferrer" target="_blank">
            <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
              White Paper
            </Text>
          </Link>
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            Contact
          </Text>
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            FAQs
          </Text>
        </Flex>
        <FlexGap height={32} />

        <Flex style={{ gap: '12px' }}>
          <Button
            as="a"
            title={t('PetaSwap Twitter')}
            href="#"
            variant="primary"
            width={48}
            height={48}
            style={{ borderRadius: '50%' }}
          >
            <TwitterIcon color="currentColor" />
          </Button>
          <Button
            as="a"
            title={t('PetaSwap Telegram')}
            href="#"
            variant="primary"
            width={48}
            height={48}
            style={{ borderRadius: '50%' }}
          >
            <TelegramIcon color="currentColor" />
          </Button>
        </Flex>
        <FlexGap height={32} />

        <Flex justifyContent="center">
          <Text fontSize={['12px', null, null, '14px']} color="#90A3BF">
            © {new Date().getFullYear()}, PetaChains All Rights Reserved
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Footer
