import { Text, Heading, Card, CardHeader, CardBody, Flex, FlexGap } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { LandingBodyWrapper } from 'views/PancakeSquad/styles'
import FoldableText from 'components/FoldableSection/FoldableText'
import configBuilder from './config'
import { StyledFaqSection, StyledDetailsWrapper } from './styles'
import React from 'react'
import ColoredWordHeading from 'views/Home/components/ColoredWordHeading'

const FaqSection = () => {
  const { t } = useTranslation()
  const config = configBuilder({ t })

  return (
    <StyledFaqSection justifyContent="center">
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Text fontSize={['24px', null, null, '46px']} textAlign={['center', null, null, 'left']} fontWeight={700} color="#fff">
          Frequently asked question{' '}
          <span
            css={`
              background: linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: inherit;
              font-weight: 700;
              margin-right: 12px;
            `}
          >
            Petaswap
          </span>
        </Text>

        <FlexGap height={16} />
        <Text textAlign={['center', null, null, 'left']} fontSize={['12px', null, null, '16px']} color="#90A3BF">
          Certainly, here are some frequently asked questions (FAQs) that you might consider for Petaswap:
        </Text>
        <FlexGap height={['32px', null, null, '56px']} />

        <Flex flexDirection={['column', null, null, 'row']}>
          <Flex flexDirection="column">
            <FoldableText
              width="100%"
              key="What is PetaSwap?"
              id="What is PetaSwap?"
              mb="24px"
              title="What is PetaSwap?"
            >
              <Text
                key="Petachains is a decentralized platform that aims to revolutionize the way people interact with and trade digital assets, leveraging blockchain technology."
                color="#90A3BF"
                as="p"
                fontSize={['12px', null, null, '14px']}
              >
                PetaSwap is a decentralized platform that aims to revolutionize the way people interact with and trade digital assets, leveraging blockchain technology.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="How does PetaSwap work?"
              id="How does PetaSwap work?"
              mb="24px"
              title="How does PetaSwap work?"
            >
              <Text key="How does PetaSwap work?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                PetaSwap operates on a decentralized network, utilizing blockchain technology to facilitate secure and transparent transactions. It enables users to trade, stake, and participate in various activities within its ecosystem.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="What sets PetaSwap apart from other platforms?"
              id="What sets PetaSwap apart from other platforms?"
              mb="24px"
              title="What sets PetaSwap apart from other platforms?"
            >
              <Text key="What sets PetaSwap apart from other platforms?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                PetaSwap distinguishes itself through its unique features, including Trade, Liquidity, Swap, Bridge, Farming, and Staking.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="Which cryptocurrencies are supported on PetaSwap?"
              id="Which cryptocurrencies are supported on PetaSwap?"
              mb="24px"
              title="Which cryptocurrencies are supported on PetaSwap?"
            >
              <Text key="Which cryptocurrencies are supported on PetaSwap?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                PetaSwap supports a variety of cryptocurrencies and tokens.
              </Text>
            </FoldableText>
          </Flex>

          <FlexGap width={92} />

          <Flex flexDirection="column">
            <FoldableText
              width="100%"
              key="How can I participate in staking on PetaSwap?"
              id="How can I participate in staking on PetaSwap?"
              mb="24px"
              title="How can I participate in staking on PetaSwap?"
            >
              <Text
                key="Petachains utilizes mechanisms like automated market making (AMM) and liquidity pools to ensure continuous liquidity within its ecosystem."
                color="#90A3BF"
                as="p"
                fontSize={['12px', null, null, '14px']}
              >
                To stake on PetaSwap, users typically need to lock a certain amount of tokens in a designated smart contract. In return, they receive rewards based on the amount staked and the duration of the staking period.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="Is PetaSwap secure?"
              id="Is PetaSwap secure?"
              mb="24px"
              title="Is PetaSwap secure?"
            >
              <Text key="Is PetaSwap secure?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                Yes, PetaSwap prioritizes security. The platform implements industry-standard security measures, undergoes regular audits, and employs encryption techniques to ensure the safety of user funds and data.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="What is the native Coin of PetaSwap, and what is its utility?"
              id="What is the native Coin of PetaSwap, and what is its utility?"
              mb="24px"
              title="What is the native Coin of PetaSwap, and what is its utility?"
            >
              <Text key="What is the native Coin of PetaSwap, and what is its utility?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                The native Coin of PetaSwap is PETA Coin. It serves various purposes within the ecosystem, including transaction fees, governance, and potentially earning rewards.
              </Text>
            </FoldableText>
            <FlexGap height={['22px', null, null, '32px']} />

            <FoldableText
              width="100%"
              key="Can I trade any digital asset on PetaSwap?"
              id="Can I trade any digital asset on PetaSwap?"
              mb="24px"
              title="Can I trade any digital asset on PetaSwap?"
            >
              <Text key="Can I trade any digital asset on PetaSwap?" color="#90A3BF" as="p" fontSize={['12px', null, null, '14px']}>
                PetaSwap supports a diverse range of digital assets. However, users are advised to check the available trading pairs on the platform for specific details.
              </Text>
            </FoldableText>
          </Flex>
        </Flex>
      </Flex>
    </StyledFaqSection>
  )
}

export default FaqSection
