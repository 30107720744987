import { Box, Button, Flex, Heading, Link, NextLinkFromReactRouter, Text } from '@pancakeswap/uikit'
import { useWeb3React } from '@pancakeswap/wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from '@pancakeswap/localization'
import Image from 'next/image'
import styled, { keyframes } from 'styled-components'
import hero from '../../../../public/images/home/hero-home.png'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  position: relative;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const Hero = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      {/* <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-light" width="100%" />
        </InnerWrapper>
      </BgWrapper> */}
      <BgWrapper style={{ backgroundColor: '#040815' }}>
          <Image src="/images/Star Animate.png" alt="" width={1000} height={600} layout="responsive" />
        </BgWrapper>
        <Flex
          position="relative"
          flexDirection={['column', null, null, 'row']}
          alignItems="center"
          justifyContent="center"
          mt={['4px', null, 0]}
          id="homepage-hero"
        >
          <Flex flex="1" flexDirection="column">
            <Box mb={['16px', null, null, '16px']}>
              <Text css={`
              background: linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            `} textAlign={['center', null, null, 'left']} color="#0163E0" fontWeight={700} fontSize={['24px', null, null, '48px']} letterSpacing={-1.62}>
                {t('Peta Swap')}
              </Text>
              <Text textAlign={['center', null, null, 'left']}  color="#fff" fontWeight={700} fontSize={['24px', null, null, '35px']} letterSpacing={[0.6, null, null, 2.62]}>
                {t("Unlocking World's Potential with Cryptocurrency")}
              </Text>
            </Box>
            <Text fontSize={['12px', null, null, '16px']} textAlign={['center', null, null, 'left']} mb={["0",null,null,"56px"]} fontWeight={400} color="#90A3BF" letterSpacing={2.62} lineHeight={1.5}>
              Devices Management DEX, DEX Aggregator, RPC, Block Explorer, KYC, Launchpad and Bridge on Peta and Binance Blockchain.
            </Text>
            <Flex justifyContent={['center', null, null, 'start']} marginTop={'24px'}>
              {!account && <ConnectWalletButton withIcon paddingLeft={'54px !important'} paddingRight={'54px  !important'}  rounded height={56} width={250} />}
            </Flex>
          </Flex>

          <Flex
            minHeight={['292px', null, null, '100%']}
            // width={['auto', null, null, '100%']}
            flex={[null, null, null, '1']}
            mb={['32px', null, null, '0']}
            mt={['32px', null, null, '0']}
            position="relative"

          >
            <BunnyWrapper>
              <Image
                sizes="(max-width: 768px) 95vw, 580px"
                src={hero}
                priority
                objectFit="fill"
                placeholder="blur"
                alt={t('Petaswap Store')}
              />
            </BunnyWrapper>
          </Flex>
        </Flex>
    </>
  )
}

export default Hero
