import { Text, Heading, Card, CardHeader, CardBody, CircleOutlineIcon, Flex, FlexGap, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { ImageUnlock } from '../../styles'
import config from './config'
import { StyledFaqSection } from '../FaqSection/styles'
import Image from 'next/image'

const FaqSection = () => {
  const { t } = useTranslation()

  return (
    <StyledFaqSection justifyContent="center" padding={0}>
      <Flex marginTop={'48px'} flexDirection="column" alignItems="center" justifyContent="center">
        <Text fontSize={['24px', null, null, '46px']} textAlign={['center', null, null, 'left']} fontWeight={700} color="#fff">
          <span
            css={`
              background: linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-size: inherit;
              font-weight: 700;
            `}
          >
            Unlock{' '}
          </span>
          the Full Potential of DeFi with Peta
        </Text>

        <FlexGap height={56} />

        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between">
          <Flex
            padding={24}
            flex={1}
            backgroundColor="#191F39"
            borderRadius={12}
            flexDirection={['column-reverse' , null, null, 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text textAlign={['center', null, null, 'left']} fontSize={['16px', null, null, '24px']} fontWeight={600} color="#fff">
                Cross-Chain Bridge
              </Text>
              <Text textAlign={['center', null, null, 'left']} fontSize={['12px', null, null, '16px']} fontWeight={400} color="#90A3BF">
                PetaSwap provides a secure bridge for transferring your crypto assets across different blockchains,
                unlocking flexibility in cross-chain exchanges.
              </Text>
            </Box>
            <FlexGap width={8} />
            <ImageUnlock src="/images/brige-1.png" alt=""  />
          </Flex>

          <FlexGap width={[null, null, null, 24]} height={[24, null, null, null]} />

          <Flex
            padding={24}
            flex={1}
            backgroundColor="#191F39"
            borderRadius={12}
            flexDirection={['column-reverse' , null, null, 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text textAlign={['center', null, null, 'left']} fontSize={['16px', null, null, '24px']} fontWeight={600} color="#fff">
                Forefront Security
              </Text>
              <Text textAlign={['center', null, null, 'left']} fontSize={['12px', null, null, '16px']} fontWeight={400} color="#90A3BF">
                Security is our top priority. Utilizing cutting-edge encryption technology and undergoing regular
                security audits,
              </Text>
            </Box>
            <FlexGap width={8} />
            <ImageUnlock src="/images/secured-2.png" alt=""  />
          </Flex>
        </Flex>

        <FlexGap height={24} />

        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex
            padding={24}
            flex={1}
            backgroundColor="#191F39"
            borderRadius={12}
            flexDirection={['column-reverse' , null, null, 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text textAlign={['center', null, null, 'left']} fontSize={['16px', null, null, '24px']} fontWeight={600} color="#fff">
                Seamless Interoperability
              </Text>
              <Text textAlign={['center', null, null, 'left']} fontSize={['12px', null, null, '16px']}  fontWeight={400} color="#90A3BF">
                With the ability to interact with various blockchain networks, PetaSwap offers users the convenience of
                accessing liquidity from diverse sources, creating a more connected ecosystem.
              </Text>
            </Box>
            <FlexGap width={8} />
            <ImageUnlock src="/images/done-2.png" />
          </Flex>

          <FlexGap width={[null, null, null, 24]} height={[24, null, null, null]} />

          <Flex
            padding={24}
            flex={1}
            backgroundColor="#191F39"
            borderRadius={12}
            flexDirection={['column-reverse' , null, null, 'row']}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text textAlign={['center', null, null, 'left']} fontSize={['16px', null, null, '24px']} fontWeight={600} color="#fff">
                Swift Asset Transfers
              </Text>
              <Text textAlign={['center', null, null, 'left']} fontSize={['12px', null, null, '16px']}  fontWeight={400} color="#90A3BF">
                The process of transferring assets across chains becomes faster and more efficient, allowing users to
                experience the benefits of liquidity in various ecosystems.
              </Text>
            </Box>
            <FlexGap width={8} />
            <ImageUnlock src="/images/fastswap-2.png"  />
          </Flex>
        </Flex>
      </Flex>
    </StyledFaqSection>
  )
}

export default FaqSection
