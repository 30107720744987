import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'
import assets2 from '../../../../../public/images/new-landing/assets-2.png'
import assets3 from '../../../../../public/images/new-landing/assets-3.png'
import assets1 from '../../../../../public/images/new-landing/assets-1.png'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything anywhere, hassle free'),
  bodyText: t(
    "PetaSwap, where trading knows no bounds. We've created a platform that empowers you to trade anything, anywhere, with utmost ease and convenience. Seamless trading on many chains with the best rates thanks to the built-in DEX aggregator.",
  ),
  reverse: true,
  primaryButton: {
    to: '/swap',
    text: t('Trade now'),
    external: false,
  },
  secondaryButton: {
    to: '#',
    text: t('Learn more'),
    external: true,
  },
  images: {
    attributes: [
      { src: assets1, alt: '' },
      { src: assets1, alt: '' },
    ],
  },
})

export const bridgeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Bridge securely between many chains'),
  bodyText: t(
    'PetaSwap establishes a secure bridge to connect various blockchain networks, ushering in a new era of safe cross-chain connectivity. With a focus on security and interoperability, PetaSwap enables the exchange of assets across different blockchains, opening doors to limitless flexibility without compromising on security.',
  ),
  reverse: false,
  primaryButton: {
    to: '/bridge',
    text: t('Bridge Now'),
    external: false,
  },
  secondaryButton: {
    to: '#',
    text: t('Learn More'),
    external: true,
  },
  images: {
    attributes: [
      { src: assets2, alt: '' },
      { src: assets2, alt: '' },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn, receive Peta tokens  when providing liquidity'),
  bodyText: t(
    'Unlock a new realm of possibilities with petaSwap! By contributing liquidity to our decentralized exchange, you not only facilitate seamless transactions but also have the opportunity to earn Peta Coins.',
  ),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Farms now'),
    external: false,
  },
  secondaryButton: {
    to: '#',
    text: t('Learn'),
    external: true,
  },
  images: {
    attributes: [{ src: assets3, alt: '' }],
  },
})
