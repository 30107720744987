import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="24" height="25" viewBox="0 0 24 25" {...props}>
      <path d="M19 13.448H13V19.448H11V13.448H5V11.448H11V5.448H13V11.448H19V13.448Z" fill="#90A3BF" />
    </Svg>
  );
};

export default Icon;
