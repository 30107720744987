import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from '@pancakeswap/utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'
import Image from 'next/image'

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="secondary" width="36px" />,
  }

  const TradesCardData: IconCardData = {
    icon: <SwapIcon color="primary" width="36px" />,
  }

  const StakedCardData: IconCardData = {
    icon: <ChartIcon color="failure" width="36px" />,
  }

  return (
    <Box
    padding={['16px' , null, null, '24px']}
      css={`
        border-radius: 12px;
        border: 10px solid rgba(45, 45, 50, 0.3);
        background: #191f39;
        margin-top: -200px;
      `}
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column" gap="24px">
        <Heading
          textAlign="center"
          color="transparent"
          // scale="xl"
          fontSize={['24px', null, null, '48px !important']}
          css={`
            background: linear-gradient(266deg, #0163E0 -55.06%, #08B2D2 131.43%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 800 !important;
          `}
        >
          {t('Multi-chain DeFi solutions')}
        </Heading>
        <Text textAlign="center" fontSize={['12px', null, null, '16px']} color="#596780">
          {t('Swap, Bridge, Farm and Launch across multiple blockchains')}
        </Text>
        <Flex justifyContent="center" alignItems="center" gap={'16px'} flexWrap="wrap" flexDirection="row">
          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-2.png" />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Hedgehog
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              box-sizing: border-box;
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-4.png" />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Earnifi
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-1.png" />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Insure
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-3.png" />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                RBX
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-6.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Dx25
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-5.png" />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Concordex
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-11.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Transit Swap
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-8.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                UniDex
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-9.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                DEMX
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-10.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={`
                  font-weight: 600;
                  color: #90a3bf;
                `}
              >
                Delio
              </Text>
            </Flex>
          </Box>

          <Box
            padding={['6px', null, null, '16px']}
            paddingRight={['12px', null, null, '16px']}
            css={`
              border-radius: 100px;
              border: 0px solid rgba(4, 8, 21, 0.2);
              background: #111527;
            `}
          >
            <Flex alignItems="center" gap="12px" flexDirection="row">
              <Image width={32} height={32} src="/images/new-landing/image-12.png" alt='petaswap' />
              <Text
                fontSize={['12px', null, null, '16px']}
                css={` font-weight: 600;color: #90a3bf;`}
              >
                Quicksilver
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Stats
