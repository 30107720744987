import React from "react";
import { ArrowDropUpIcon, ChevronUpIcon, MinusIcon, PlusIcon, ArrowDropDownIcon } from "../Svg";
import Button from "./Button";
import IconButton from "./IconButton";
// import footer from "../../style/Roi.module.css";
import { isMobile } from "react-device-detect";

interface Props {
  onClick?: () => void;
  expanded?: boolean;
  color?: string;
}

export const ExpandableButton: React.FC<React.PropsWithChildren<Props>> = ({ onClick, expanded, children }) => {
  return (
    <IconButton aria-label="Hide or show expandable content" onClick={onClick}>
      {children}
      {expanded ? <MinusIcon color="invertedContrast" /> : <PlusIcon color="invertedContrast" />}
    </IconButton>
  );
};
ExpandableButton.defaultProps = {
  expanded: false,
  color: '#0163E0'
};

export const ExpandableLabel: React.FC<React.PropsWithChildren<Props>> = ({ onClick, expanded, children, color }) => {
  return (
    <Button
      variant="text"
      style={{
        background: expanded ? "transparent" : "transparent",
      }}
      aria-label="Hide or show expandable content"
      onClick={onClick}
      endIcon={expanded ? <ArrowDropUpIcon color={color} /> : <ArrowDropDownIcon color={color} />}
    >
      {children}
    </Button>
  );
};
ExpandableLabel.defaultProps = {
  expanded: false,
};
