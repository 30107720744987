import { Flex, Text, Box, Input, Button, Link, NextLinkFromReactRouter as RouterLink } from '@pancakeswap/uikit'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'
import { useState } from 'react'
import Image from 'next/image'
import FireIcon from './assets/fire.tsx'
import AssetsIcon4 from './assets/assets-4.tsx'
import AssetsIcon2 from './assets/assets-2.tsx'
import AssetsIcon3 from './assets/assets-3.tsx'

interface SalesSwapButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSwapProps {
  headingText: string
  bodyText: string
  reverse: boolean
  primaryButton: SalesSwapButton
  secondaryButton: SalesSwapButton
  images: CompositeImageProps
}

const SalesSwap: React.FC<React.PropsWithChildren<SalesSwapProps>> = (props) => {
  const { headingText, bodyText, reverse, primaryButton, secondaryButton, images } = props
  const [tabActive, setTabActive] = useState<'swap' | 'liquidity' | 'bridge'>('swap')
  const [percentage, setPercentage] = useState<'25' | '50' | '75' | '100'>('0')

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, null, reverse && '64px']}
          mr={[null, null, null, !reverse && '64px']}
          alignSelf="center"
        >
          <ColoredWordHeading text={headingText} />
          <Text
            color="textSubtle"
            fontSize={['12px', null, null, '16px']}
            textAlign={['center', null, null, 'left']}
            css={`
              color: #90a3bf;
              font-weight: 400;
            `}
            mb="24px"
          >
            {bodyText}
          </Text>
          <Flex gap="20px" justifyContent={['center', null, null, 'start']}>
            <Button
              paddingX={['32px', null, null, '60px']}
              paddingY={["13px", null, null, "13px"]}
              rounded={true}
              height={['56px', null, null ,'56px']}
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
              `}
              mr="16px"
            >
              <Link
                css={`
                  text-decoration: none !important;
                `}
                external
                href={primaryButton.to}
              >
                <Text color="card" bold fontSize={['16px', null, null, "16px"]}>
                  {primaryButton.text}
                </Text>
              </Link>
            </Button>
            <Link
              css={`
                text-decoration: none !important;
              `}
              fontSize={['16px', null, null, "16px"]}
              external
              href={secondaryButton.to}
            >
              {secondaryButton.text} <Image width={20} height={20} src="/images/new-landing/arrow-up.svg" />
            </Link>
          </Flex>
        </Flex>
        <Flex
          maxHeight={['192px', null, null, '400px']}
          height={['192px', null, null, '400px']}
          width={['192px', null, null, '400px']}
          // flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
        >
          <CompositeImage {...images} />
        </Flex>
        {/* <Box
          maxHeight={['100%', null, null, '600px']}
          height={['100%', null, null, '600px']}
          width={['100%', null, null, '400px']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          css={`
            padding: 24px;
            border-radius: 12px;
            border: 10px solid rgba(45, 45, 50, 0.3);
            background: #191f39;
          `}
        >
          <Flex justifyContent="center" gap="12px" alignItems="center">
            <Button
              onClick={() => setTabActive('swap')}
              css={`
                ${tabActive !== 'swap' && 'background: #111527 !important; color: #90a3bf;'}
                flex: 1;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              Swap
            </Button>

            <Button
              onClick={() => setTabActive('liquidity')}
              css={`
                ${tabActive !== 'liquidity' && 'background: #111527 !important; color: #90a3bf;'}
                flex: 1;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              Liquidity
            </Button>

            <Button
              onClick={() => setTabActive('bridge')}
              css={`
                ${tabActive !== 'bridge' && 'background: #111527 !important; color: #90a3bf;'}
                flex: 1;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              Bridge
            </Button>
          </Flex>
          <Flex flexDirection="column" gap="16px" marginTop="16px">
            <Flex alignItems="center" justifyContent="space-between" marginBottom="12px">
              <Box>
                <Text
                  css={`
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 12px;
                    margin-bottom: 4px;
                  `}
                >
                  Dex Aggregator
                </Text>
                <Text
                  css={`
                    color: #90a3bf;
                    font-size: 12px;
                    font-weight: 400;
                  `}
                >
                  Trade tokens in an instant
                </Text>
              </Box>
              <Flex gap="8px">
                <FireIcon color="#596780" />
                <AssetsIcon2 color="#0163E0" />
                <AssetsIcon3 color="#596780" />
                <AssetsIcon4 color="#596780" />
              </Flex>
            </Flex>

            <Box>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <Image src="/images/new-landing/icon.png" width={24} height={24} />
                  <Text
                    css={`
                      color:#fff);
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    City ($CITY)
                  </Text>
                </Flex>
                <Text
                  css={`
                    color: #90a3bf;
                    font-size: 12px;
                    font-weight: 400;
                  `}
                >
                  Balance : 0.00
                </Text>
              </Flex>
              <Box marginTop="14px">
                <Input
                  type="number"
                  css={`
                    display: flex;
                    height: 84px;
                    padding: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 8px;
                    background: #111527;
                    color: #a3aed0;
                    font-size: 24px;
                    font-weight: 600;
                  `}
                />
              </Box>
            </Box>

            <Flex gap="6px" alignItems="center">
              <Box
                onClick={() => setPercentage('25')}
                css={`
                  cursor: pointer;
                  display: flex;
                  padding: 2px 6px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                  border-radius: 8px;
                  background: #111527;
                `}
              >
                <Text
                  css={`
                    ${percentage === '25' ? 'color : #0163E0;' : 'color: #90a3bf;'}
                    font-size: 10px;
                    font-weight: 600;
                  `}
                >
                  25%
                </Text>
              </Box>
              <Box
                onClick={() => setPercentage('50')}
                css={`
                  cursor: pointer;
                  display: flex;
                  padding: 2px 6px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                  border-radius: 8px;
                  background: #111527;
                `}
              >
                <Text
                  css={`
                    ${percentage === '50' ? 'color : #0163E0;' : 'color: #90a3bf;'}
                    font-size: 10px;
                    font-weight: 600;
                  `}
                >
                  50%
                </Text>
              </Box>
              <Box
                onClick={() => setPercentage('75')}
                css={`
                  cursor: pointer;
                  display: flex;
                  padding: 2px 6px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                  border-radius: 8px;
                  background: #111527;
                `}
              >
                <Text
                  css={`
                    ${percentage === '75' ? 'color : #0163E0;' : 'color: #90a3bf;'}
                    font-size: 10px;
                    font-weight: 600;
                  `}
                >
                  75%
                </Text>
              </Box>
              <Box
                onClick={() => setPercentage('100')}
                css={`
                  cursor: pointer;
                  display: flex;
                  padding: 2px 6px;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                  flex: 1 0 0;
                  border-radius: 8px;
                  background: #111527;
                `}
              >
                <Text
                  css={`
                    ${percentage === '100' ? 'color : #0163E0;' : 'color: #90a3bf;'}
                    font-size: 10px;
                    font-weight: 600;
                  `}
                >
                  100%
                </Text>
              </Box>
            </Flex>

            <Box>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <Image src="/images/new-landing/icon-2.png" width={24} height={24} />
                  <Text
                    css={`
                      color: #fff;
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    USDT ($USDT)
                  </Text>
                </Flex>
                <Text
                  css={`
                    color: #90a3bf;
                    font-size: 12px;
                    font-weight: 400;
                  `}
                >
                  Balance : 0.00
                </Text>
              </Flex>
              <Box marginTop="14px">
                <Input
                  type="number"
                  css={`
                    display: flex;
                    height: 84px;
                    padding: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 8px;
                    background: #111527;
                    color: #a3aed0;
                    font-size: 24px;
                    font-weight: 600;
                  `}
                />
              </Box>
            </Box>

            <Flex justifyContent="space-between" alignItems="center">
              <Text
                css={`
                  color: #2b3674;
                  font-size: 12px;
                  font-weight: 600;
                `}
              >
                Slippage Tolerance
              </Text>
              <Flex alignItems="center">
                <Text
                  css={`
                    color: #90a3bf;
                    font-size: 12px;
                    font-weight: 400;
                  `}
                >
                  0.0%
                </Text>
                <Image alt='' src="/images/new-landing/pencil.svg" width={16} height={16} />
              </Flex>
            </Flex>

            <Button
              css={`
                width: 100%;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              Swap
            </Button>
          </Flex>
        </Box> */}
      </Flex>
    </Flex>
  )
}

export default SalesSwap
